<template>
  <div style="text-align: left">审批信息</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="顾客手机号" style="margin-left: 20px">
      <el-input
        v-model="seachForm.cellphone"
        clearable
        size="small"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item label="订单编号" style="margin-left: 20px">
      <el-input
        v-model="seachForm.orderCode"
        clearable
        size="small"
        placeholder="请输入订单编号"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column type="expand">
      <template #default="props">
        <el-form
          label-position="left"
          inline
          class="demo-table-expand"
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-form-item label="商品名称" style="width: 35%">
            <span>{{ props.row.itemName }}</span>
          </el-form-item>
          <el-form-item label="商品简介" style="width: 35%">
            <span>{{ props.row.content }}</span>
          </el-form-item>
          <el-form-item label="商品价格" style="width: 35%">
            <span>{{ props.row.price }}</span>
          </el-form-item>
          <el-form-item label="购买数量" style="width: 35%">
            <span>{{ props.row.number }}</span>
          </el-form-item>
          <el-form-item label="团长名称" style="width: 35%">
            <span>{{ props.row.managerName }}</span>
          </el-form-item>
          <el-form-item label="团长手机" style="width: 35%">
            <span>{{ props.row.managerPhone }}</span>
          </el-form-item>
        </el-form>
      </template>
    </el-table-column>
    <!-- <el-table-column prop="applyTypeVal" label="申请类别" width="150">
      <template #default="scope">
        <el-tag
          :type="
            scope.row.applyType === 'GROUPMANAGER'
              ? 'warning'
              : scope.row.applyType === 'WITHDRAW'
              ? 'info'
              : 'danger'
          "
          >{{ scope.row.applyTypeVal }}</el-tag
        >
      </template>
    </el-table-column> -->
    <el-table-column prop="orderCode" label="订单编号"> </el-table-column>
    <el-table-column prop="orderCode" label="商品图片">
      <template #default="scope">
        <img
          v-if="scope.row.pictureUrl"
          :src="imgBaseUrl + scope.row.pictureUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="customer" width="200" label="顾客名称">
    </el-table-column>
    <el-table-column prop="cellphone" width="180" label="手机号">
    </el-table-column>
    <el-table-column prop="itemName" label="商品名称"> </el-table-column>
    <el-table-column prop="createDate" label="申请时间"> </el-table-column>
    <el-table-column prop="note" label="备注"> </el-table-column>

    <el-table-column prop="profile" label="审批状态" width="150">
      <template #default="scope">
        <el-tag type="info" v-if="scope.row.state === 'TODO'">
          {{ "未处理" }}
        </el-tag>
        <el-tag type="success" v-else-if="scope.row.state === 'DONE'">
          {{ "已处理" }}
        </el-tag>
        <!-- formate(scope.row.state) -->
        <el-tag type="danger" v-else-if="scope.row.state === 'CANCELLED'">
          {{ "已取消" }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="操作">
      <template #default="scope">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <el-button
            type="text"
            v-if="scope.row.state === 'TODO'"
            @click="authorzation(scope.row)"
            class="marginleft10"
            >审批</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="审批" v-model="dialogVisible" width="400px">
    <el-form
      ref="form"
      :model="authorzationForm"
      size="small"
      label-width="80px"
    >
      <el-form-item
        label="审批"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
        <el-switch
          v-model="authorzationForm.state"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="remark"
        style="text-align: left"
        size="mini"
      >
        <div style="display: flex">
          <el-input
            v-model="authorzationForm.remark"
            clearable
            placeholder="请输入备注！"
            style="width: 230px"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitauthorzation"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {
      seachForm: {
        cellphone: '',
        orderCode: ''
      },
      authorzationForm: {
        state: true,
        remark: ''
      },
      dialogVisible: false,
      tableData: [],
      copyrowId: '',
      authorzationId: '',
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      applyTypeOption: [],
      checkResultOption: [],
      imgBaseUrl: '',
      stateOption: []
    }
  },
  computed: {
    formate() {
      return (val) => {
        const i = this.stateOption.findIndex(item => item.key === val)
        // console.log(item)
        return this.stateOption[i].label || ''
      }
    }
  },
  created() {
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'

    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '审批管理',
        index: '/authorzationlist'
      },
      {
        name: '审批列表',
        index: '/authorzationlist'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getTableData(1)
    this.getcheckResult()
    this.getstate()
  },
  methods: {
    async getstate() {
      const res = await this.$http.get('/nktdec/salesreturn/processstates')
      console.log('state数据', res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.stateOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async getcheckResult() {
      const res = await this.$http.get('/nktdec/application/checkresults')
      // console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.checkResultOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/1?pageSize' + val + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone) + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getTableData(no) {
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/' + no)
      console.log('团长退货订单项', res.data.content)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone) + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode)
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.cellphone.trim() === '' ? '' : 'cellphone=' + this.seachForm.cellphone) + (this.seachForm.orderCode.trim() === '' ? '' : (this.seachForm.cellphone.trim() === '' ? '' : '&') + 'orderCode=' + this.seachForm.orderCode)
      console.log(str)
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/1')
      console.log(res.data)
      this.seachForm.cellphone = ''
      this.seachForm.orderCode = ''
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async authorzation(row) {
      this.dialogVisible = true
      this.authorzationId = row.id
    },
    async submitauthorzation(row) {
      var tmpArr = _.cloneDeep(this.authorzationForm)
      if (tmpArr.remark.trim() === '') {
        this.$message.error('请您填写您的审核原因！')
        return
      }
      tmpArr.state = tmpArr.state ? 'DONE' : 'CANCELLED'
      console.log(tmpArr)
      const res = await this.$http.put('/nktdec/salesreturn/salesreturn/' + this.authorzationId, tmpArr)
      console.log(res)
      this.getTableData(1)
      this.dialogVisible = false
      this.authorzationForm.remark = ''
    },
  }
}
</script>
<style scoped>
</style>
