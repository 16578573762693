<template>
  <div style="text-align: left">审批信息</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <!-- <el-form-item label="申请类型">
      <el-select
        v-model="seachForm.applyType"
        size="small"
        placeholder="请输入申请类型"
      >
        <el-option
          v-for="(item, index) in applyTypeOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="手机号" style="margin-left: 20px">
      <el-input
        v-model="seachForm.cellphone"
        clearable
        size="small"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item label="审核结果" style="margin-left: 20px">
      <el-select
        v-model="seachForm.checkResult"
        clearable
        size="small"
        placeholder="请输入申请类型"
      >
        <el-option
          v-for="(item, index) in checkResultOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="applyTypeVal" label="申请类别" width="150">
      <template #default="scope">
        <el-tag
          :type="
            scope.row.applyType === 'GROUPMANAGER'
              ? 'warning'
              : scope.row.applyType === 'WITHDRAW'
              ? 'info'
              : 'danger'
          "
          >{{ scope.row.applyTypeVal }}</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column prop="orderCode" width="180" label="订单编号">
      <template #default="scope">
        <el-tag class="jumporder" @click="pushtodetaile(scope.row)">{{
          scope.row.orderCode
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="cellphone" width="180" label="手机号">
    </el-table-column>
    <el-table-column prop="customer" width="200" label="顾客名称">
    </el-table-column>
    <el-table-column prop="createDate" label="申请时间"> </el-table-column>
    <el-table-column prop="remark" label="备注"> </el-table-column>

    <el-table-column prop="profile" label="审批状态" width="150">
      <template #default="scope">
        <el-tag type="info" v-if="scope.row.result === 'NEWCREATED'">
          {{ scope.row.resultVal }}
        </el-tag>
        <el-tag type="success" v-else-if="scope.row.result === 'APPROVED'">
          {{ scope.row.resultVal }}
        </el-tag>
        <el-tag type="danger" v-else-if="scope.row.result === 'REJECTED'">
          {{ scope.row.resultVal }}
        </el-tag>
        <el-tag type="warning" v-else>
          {{ scope.row.resultVal }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="操作">
      <template #default="scope">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <el-button
            type="text"
            v-if="scope.row.result === 'NEWCREATED'"
            @click="authorzation(scope.row)"
            class="marginleft10"
            >审批</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog title="审批" v-model="dialogVisible" width="400px">
    <el-form
      ref="form"
      :model="authorzationForm"
      size="small"
      label-width="80px"
    >
      <el-form-item
        label="审批"
        prop="teamTimeproid"
        style="width: 50%; text-align: left"
      >
        <el-switch
          v-model="authorzationForm.checkResult"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="审核原因"
        prop="remark"
        style="text-align: left"
        size="mini"
      >
        <div style="display: flex">
          <el-input
            v-model="authorzationForm.remark"
            clearable
            placeholder="请输入审核原因！"
            style="width: 230px"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitauthorzation"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {
      seachForm: {
        cellphone: '',
        checkResult: ''
      },
      authorzationForm: {
        checkResult: true,
        remark: ''
      },
      dialogVisible: false,
      tableData: [],
      copyrowId: '',
      authorzationId: '',
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      applyTypeOption: [],
      checkResultOption: []
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '审批管理',
        index: '/authorzationlist'
      },
      {
        name: '审批列表',
        index: '/authorzationlist'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getTableData(1)
    this.getapplyType()
    this.getcheckResult()
    // this.getsalereturns()
  },
  methods: {
    async getapplyType() {
      const res = await this.$http.get('/nktdec/application/applytypes')
      console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.applyTypeOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async getcheckResult() {
      const res = await this.$http.get('/nktdec/application/checkresults')
      console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.checkResultOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    pushtodetaile(item) {
      console.log(item)
      this.$router.push('/cancledetaile/' + item.orderCode)
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/application/applications/1' + '?applyType=CANCLEORDER&pageSize=' + val + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone) + (this.seachForm.checkResult.trim() === '' ? '' : '&checkResult=' + this.seachForm.checkResult))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getsalereturns() {
      const res = await this.$http.get('/nktdec/salesreturn/salesreturns/1')
      console.log('团长退货订单项', res)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?applyType=CANCLEORDER&pageSize=' + this.pageSize + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone) + (this.seachForm.checkResult.trim() === '' ? '' : '&checkResult=' + this.seachForm.checkResult)
      const res = await this.$http.get('/nktdec/application/applications/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getTableData(pageno) {
      const res = await this.$http.get('/nktdec/application/applications/' + pageno + '?applyType=CANCLEORDER')
      console.log('退款申请', res.data.content)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?applyType=CANCLEORDER&' + (this.seachForm.cellphone.trim() === '' ? '' : 'cellphone=' + this.seachForm.cellphone) + (this.seachForm.checkResult.trim() === '' ? '' : (this.seachForm.cellphone.trim() === '' ? '' : '&') + 'checkResult=' + this.seachForm.checkResult)
      console.log(str)
      const res = await this.$http.get('/nktdec/application/applications/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/application/applications/1?applyType=CANCLEORDER')
      console.log(res.data)
      this.seachForm.applyType = ''
      this.seachForm.cellphone = ''
      this.seachForm.checkResult = ''
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async authorzation(row) {
      this.dialogVisible = true
      this.authorzationId = row.id
      //  const res =await this.$http.put('')
    },
    async submitauthorzation(row) {
      var tmpArr = _.cloneDeep(this.authorzationForm)
      if (tmpArr.remark.trim() === '') {
        this.$message.error('请您填写您的审核原因！')
        return
      }
      tmpArr.checkResult = tmpArr.checkResult ? 'APPROVED' : 'REJECTED'
      console.log(tmpArr)
      const res = await this.$http.put('/nktdec/application/application/' + this.authorzationId, tmpArr)
      console.log(res)
      this.getTableData(1)
      this.dialogVisible = false
      this.authorzationForm.remark = ''
    },
  }
}
</script>
<style scoped>
.jumporder {
  cursor: pointer;
}
</style>
